import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';

function MyForm2() {
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://satsumadiscord.onrender.com/send_webhook2', { message });
            console.log(response);
            alert(`メッセージの送信に成功しました！`)
            // データの送信が成功したら適切な処理を行う
        } catch (err) {
            console.error(err.message);
            alert(`メッセージの送信に失敗しました！${err}`)
            // エラーが発生した場合に適切な処理を行う
        }
    };
    return (
        <>
            <center>
                <h2>SENGOKU 目安箱フォーム</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="feedbackMessage">
                        <Form.Label>
                            今後のクラン運営をより良くするために忌憚のないメッセージをください。<br />
                            完全匿名で送信されるので、どストレートに思う事を書いて大丈夫です。改善案以外にも不満や問題点を書くだけでも良いので気軽にメッセージください<br />
                            <br />
                            例<br />
                            <li>なんでもチャンネル増やして</li>
                            <li>正直xxだからooした方が良いと思う</li>
                            <li>初心者講習もうやらないの？</li>
                            <br />
                            ※全ての意見に目を通しはしますが、実現できるか確約はできないです。すみません。<br />
                            もっとこうしたら良いのに等意見があればクラン運営側に招待しようと思いますので幹部の誰かに連絡ください。<br />
                            <br />
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            style={{ width: '80%' }}
                            placeholder="好きに書いてね"
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        送信！
                    </Button>
                </Form>
            </center>
        </>
    );
}

export default MyForm2;