import './App.css';
import MyForm from './MyForm';
import Result from './Result'
import MyForm2 from './MyForm2';
import Result2 from './Result2'
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>SENGOKUフォーム</title>
          <meta name="description" content="SENGOKUクラン用に設立された匿名で意見を伝えるサイトです" />
        </Helmet>
        <Routes>
          <Route path="/" element={<>
            <MyForm />
            <Result />
          </>} />
          <Route path="/meyasu" element={<>
            <MyForm2 />
            <Result2 />
          </>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
