function Result() {
    return (<>
        <details>
            <summary>[7/13起案] 指揮に関する意見</summary>
            7/13 意見を幹部組にエスカレして話し合いする場を設けました。<br />
            まとまり次第全体連絡をしようと思います。<br />
            <br />
            意見を頂きありがとうございました。また何かありましたら送信ください。<br />
            <br />
        </details>
        <details>
            <summary>[8/10起案] 指揮について</summary>
            現在話し合い中です。<br />
            <br />
            意見を頂きありがとうございました。また何かありましたら送信ください。<br />
            <br />
        </details>
        <details>
            <summary>[8/11起案] モチベーション不足について</summary>
            島津から改めてメリットを説明することに致します。<br />
            <br />
            意見を頂きありがとうございました。また何かありましたら送信ください。<br />
            <br />
        </details>
        <details>
            <summary>[8/11起案] 問題発言について</summary>
            本人に注意します。<br />
            <br />
            意見を頂きありがとうございました。また何かありましたら送信ください。<br />
            <br />
        </details>

    </>
    )
}

export default Result;